function init() {
	// console.log("Do ADA scripts.");
	// Fix duplicate id issues with fixed header
	// document.querySelector("#ast-fixed-header #ast-desktop-header").id =
	// 	"ast-desktop-fixed-header";
	// document.querySelector(
	// 	"#ast-fixed-header #secondary_menu-site-navigation-desktop"
	// ).id = "secondary_menu-site-fixed-navigation-desktop";
	// document.querySelector("#ast-fixed-header #ast-hf-menu-2").id =
	// 	"ast-hf-fixed-menu-2";
	// document.querySelector(
	// 	"#ast-fixed-header #primary-site-navigation-desktop"
	// ).id = "primary-site-fixed-navigation-desktop";
	// document.querySelector("#ast-fixed-header #ast-hf-menu-1").id =
	// 	"ast-hf-fixed-menu-1";
	// document.querySelector("#ast-fixed-header #ast-mobile-header").id =
	// 	"ast-mobile-fixed-header";

	// Fix empty links added by Spectra for linking entire elements
	const emptyLinks = document.querySelectorAll(
		".spectra-container-link-overlay"
	);
	emptyLinks.forEach((link) => {
		const newSpan = document.createElement("span");
		link.appendChild(newSpan);

		const span = link.querySelector("span");
		span.classList.add("visually-hidden");
		span.textContent = "Link";
	});

	// Add label to Astra search field
	const searchField = document.querySelector(
		".ast-inline-search .search-field"
	);
	if (searchField) {
		searchField.setAttribute("title", "Search");
	}
}

export default { init };
