// Breakpoints
const e10kBreakpoints = {
	lg: 1200,
	md: 992,
	sm: 768,
	xs: 480,
	nav: 1201
};

// Theme variables
// Access varaibles from the theme, add more via the functions.php file
// themeVars.templateURL - gets the child base URL

// Format phone numbers; populate phone number link based on element text
// Todo: Add support for extensions
function e10kFormatPhoneNumbers() {
	const phoneNumbers = document.querySelectorAll("a.tel, .tel a");

	// Loop through phone numbers
	for (let i = 0; i < phoneNumbers.length; i++) {
		let phoneNumber = "";

		// Remove '1-' prefix
		phoneNumber = phoneNumbers[i].text.replace(/^(1-)/, "");

		// Add 'tel:1' prefix; remove non-digit characters like '-'
		phoneNumber = "tel:1" + phoneNumber.replace(/\D/g, "");

		// Set element href attribute value to updated phone number
		phoneNumbers[i].setAttribute("href", phoneNumber);
	}
}

// Use IntersectionObserver to detect if element is in the viewport; add or remove 'is-visible' class based on this
function e10kInViewport(element) {
	const el = document.querySelector(element);
	const observer = new window.IntersectionObserver(
		([entry]) => {
			if (entry.isIntersecting) {
				console.log("Enter");
				el.classList.add("is-visible");
				return;
			} else {
				console.log("Exit");
				el.classList.remove("is-visible");
			}
		},
		{
			root: null,
			threshold: 0.5
		}
	);

	observer.observe(el);
}

// Remove empty <p> tags including those that contain spaces
function e10kRemoveEmptyPTags(els) {
	els.forEach((el) => {
		const str = el.innerHTML;
		const updatedStr = str.replace(/<p[^>]*>(?:\s|&nbsp;)*<\/p>/gi, "");
		el.innerHTML = updatedStr;
	});
}

export default {
	e10kBreakpoints,
	e10kFormatPhoneNumbers,
	e10kInViewport,
	e10kRemoveEmptyPTags
};
