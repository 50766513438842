function init() {
	// Remove archive description from specific pages
	const pages = document.querySelectorAll(".search");
	if (pages) {
		const pagesRemoveArchiveDescription = removeArchiveDescription(pages);
	}

	// Add placeholder text to search form
	const searchforms = document.querySelector(".search-form .search-field");
	if (searchforms) {
		const searchFormAddPlaceholder = addPlaceholder(searchforms);
	}
}

function removeArchiveDescription(pages) {
	pages.forEach((el) => {
		const archiveDescriptionEl = el.querySelector(
			".ast-archive-description"
		);

		archiveDescriptionEl.remove();
	});
}

function addPlaceholder(searchforms) {
	searchforms.setAttribute("placeholder", "Search");
}

export default { init };
