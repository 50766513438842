import EmblaCarousel from "embla-carousel";
import ClassNames from "embla-carousel-class-names";
import { autoPlay } from "./autoplay";
import {
	addDotBtnsAndClickHandlers,
	addPrevNextBtnsClickHandlers,
	addTogglePrevNextBtnsActive
} from "./navigation";

function init() {
	// console.log("Grid slider");

	const gridSliderEls = document.querySelectorAll(
		".grid-slider.embla:not(.mobile-slider-false)"
	);
	if (gridSliderEls.length) {
		gridSliderEls.forEach((el) => {
			gridSlider(el);
		});
	}
}

function gridSlider(el) {
	const wrap = el;
	const viewPort = wrap.querySelector(".embla__viewport");
	const dots = wrap.querySelector(".embla__dot_navigation");
	const embla = EmblaCarousel(
		viewPort,
		{
			active: true,
			align: "start",
			breakpoints: {
				"(min-width: 992px)": { active: false }
			},
			containScroll: "trimSnaps",
			dragFree: true
			// loop: true
		},
		[ClassNames()]
	);

	// Dot navigation
	const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
		embla,
		dots
	);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

export default { init };
