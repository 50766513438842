import utils from "../utils";

function init() {
	// console.log("Image dialog");

	const imageDialogEls = document.querySelectorAll(".image-dialog");

	if (imageDialogEls.length) {
		const imageDialog = dialogEventListeners({
			open: document.querySelectorAll(".image-dialog-link"),
			dialog: imageDialogEls,
			close: document.querySelectorAll(".image-dialog .close")
		});
	}
}

function openDialog(e) {
	// console.log("Open dialog!");

	const image = e.target.closest(".image");
	const dialog = image.querySelector("dialog");

	e.preventDefault();
	dialog.showModal();
}

// Close dialog
function closeDialog(e) {
	// console.log("Close dialog!");

	const image = e.target.closest(".image");
	const dialog = image.querySelector("dialog");

	dialog.close();
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open
	args.open.forEach((el) => {
		el.addEventListener("click", (e) => {
			openDialog(e);
		});
	});

	// Close
	// On ::backdrop click
	args.dialog.forEach((el) => {
		el.addEventListener("click", (e) => {
			if (e.target.nodeName === "DIALOG") {
				closeDialog(e);
			}
		});
	});

	// On close button click
	args.close.forEach((el) => {
		el.addEventListener("click", (e) => {
			closeDialog(e);
		});
	});
}

export default { init };
