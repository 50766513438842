// import jQuery from "jquery";
window.$ = jQuery;
window.jquery = jQuery;

const Test = {
	log() {
		console.log("Test module is working!");
		console.log($);
	}
};

export default Test;
