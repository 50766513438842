import Player from "@vimeo/player";

function init() {
	// console.log("Video scripts");

	// Site background
	const siteBgVideo = document.querySelector(
		"body > .site-background .background-video .video"
	);

	if (siteBgVideo) {
		const backgroundVideoFull = bgVideoFull(siteBgVideo, 16 / 9);
		const siteBackgroundVideoFadeIn = siteBgVideoFadeIn(siteBgVideo);
	}

	// Banner background
	const bannerBgVideo = document.querySelector(
		".banner-slider .slide .background-video .video"
	);

	if (bannerBgVideo) {
		const backgroundVideoFull = bgVideoFull(bannerBgVideo, 16 / 9);
		const bannerBackgroundVideoFadeIn = bannerBgVideoFadeIn(bannerBgVideo);
	}
}

// Background video fullwidth/height
function bgVideoFull(frame, frameRatio) {
	const viewport = frame.parentElement;

	function resizeVideo(frame) {
		const width = viewport.offsetWidth;
		const height = viewport.offsetHeight;
		const ratio = width / height;
		let targetWidth = width;
		let targetHeight = height;

		if (ratio > frameRatio) {
			// Viewport is wider than video
			// Correct the height
			targetHeight = width / frameRatio;
		} else {
			// Viewport is taller than video
			// Correct the width
			targetWidth = height * frameRatio;
		}

		// Set frame width and height
		frame.setAttribute(
			"style",
			"width: " + targetWidth + "px; height: " + targetHeight + "px"
		);
	}
	window.addEventListener("load", function () {
		resizeVideo(frame);
	});
	window.addEventListener("resize", function () {
		resizeVideo(frame);
	});
}

function siteBgVideoFadeIn(iframe) {
	if (iframe) {
		const player = new Player(iframe);

		// On play
		player.on("play", function () {
			// Fade in video
			iframe.classList.add("fade-in-video");
		});
	}
}

function bannerBgVideoFadeIn(iframe) {
	if (iframe) {
		const player = new Player(iframe);
		const slide = iframe.closest(".slide");
		let videoDuration = 0;

		// On play
		player.on("play", function () {
			// console.log("Video playing");
			function fadeOut(delay) {
				setTimeout(function () {
					// console.log("Fade out");
					slide.classList.remove("fade-in-down-content");
					slide.classList.add("fade-out-up-content");
				}, delay);
			}

			// Fade in video
			slide.classList.add("fade-in-video");

			// Fade out content after 2 seconds initially
			fadeOut(2000);

			// Fade in content
			player.getDuration().then(function (duration) {
				let videoDuration = duration;

				setInterval(function () {
					slide.classList.remove("fade-out-up-content");
					slide.classList.add("fade-in-down-content");

					// Fade out content after 4 seconds
					fadeOut(4000);
				}, videoDuration * 1000);

				// First loop
				// const fadeInLoop1 = setInterval(function () {
				// 	console.log("Fade in 1");
				// 	slide.classList.remove("fade-out-up-content");
				// 	slide.classList.add("fade-in-down-content");

				// 	// Fade out content after 4 seconds
				// 	fadeOut(4000);
				// }, (videoDuration - 4) * 1000);
				// clearInterval(fadeInLoop1);

				// Subsequent loops
				// setTimeout(function () {
				// 	setInterval(function () {
				// 		console.log("Fade in 2");
				// 		slide.classList.remove("fade-out-up-content");
				// 		slide.classList.add("fade-in-down-content");

				// 		// Fade out content after 4 seconds
				// 		fadeOut(4000);
				// 	}, videoDuration * 1000);
				// }, videoDuration * 1000);
			});
		});
	}
}

export default { init };
