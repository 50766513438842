// import lightbox from "lightbox2";
// Added script and styles via functions.php due to jquery issues

function init() {
	// console.log("Gallery.");
	// console.log(lightbox);

	const galleryBlockEls = document.querySelector(".block-gallery.layout-full");
	if (galleryBlockEls) {
		const galleryLightbox = lightboxOptions();
	}
}

function lightboxOptions() {
	lightbox.option({
		disableScrolling: true,
		fadeDuration: 300,
		imageFadeDuration: 300,
		resizeDuration: 100,
		wrapAround: true
	});
}

export default { init };
