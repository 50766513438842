import EmblaCarousel from "embla-carousel";
import ClassNames from "embla-carousel-class-names";
import { autoPlay } from "./autoplay";
import {
	addDotBtnsAndClickHandlers,
	addPrevNextBtnsClickHandlers,
	addTogglePrevNextBtnsActive
} from "./navigation";

function init() {
	// console.log("CTA slider");

	const ctaSliderEls = document.querySelectorAll(".cta-slider.embla");

	if (ctaSliderEls.length) {
		ctaSliderEls.forEach((el) => {
			ctaSlider(el);
		});
	}
}

function ctaSlider(el) {
	const viewPort = el.querySelector(".embla__viewport");
	const prevBtn = el.querySelector(".embla__button--prev");
	const nextBtn = el.querySelector(".embla__button--next");
	const dots = el.querySelector(".embla__dot_navigation");

	// Add disabled class if slider has 1 slide
	const slides = el.querySelectorAll(".slide");
	let status = true;
	if (slides.length === 1) {
		el.classList.add("disabled");
		status = false;
	}

	const embla = EmblaCarousel(
		viewPort,
		{
			active: status,
			align: "start",
			loop: true
		},
		[ClassNames()]
	);

	// Previous/next navigation
	const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
		embla,
		prevBtn,
		nextBtn
	);

	const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
		embla,
		prevBtn,
		nextBtn
	);

	// Dot navigation
	const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
		embla,
		dots
	);

	// Autoplay
	// const auto = autoPlay(embla, viewPort, 5000);

	// Ready
	const toggleEmblaReady = (event) => {
		const isResizeEvent = event === "resize";
		const toggleClass = isResizeEvent ? "remove" : "add";
		viewPort.classList[toggleClass]("embla--is-ready");
		if (isResizeEvent) embla.reInit();
	};

	embla.on("init", toggleEmblaReady);
	embla.on("resize", toggleEmblaReady);
	embla.on("reInit", toggleEmblaReady);
}

export default { init };
