import ada from "./modules/ada";
import astra from "./modules/astra";
import dialogContact from "./modules/dialogs/contact";
import dialogDonate from "./modules/dialogs/donate";
import dialogEventRegistration from "./modules/dialogs/event-registration";
import dialogImage from "./modules/dialogs/image";
import dialogScheduleService from "./modules/dialogs/schedule-service";
import dialogVideo from "./modules/dialogs/video";
import gallery from "./modules/gallery";
import pagination from "./modules/pagination";
import sliderAircraft from "./modules/sliders/aircraft";
import sliderBanner from "./modules/sliders/banner";
import sliderCta from "./modules/sliders/cta";
import sliderGallery from "./modules/sliders/gallery";
import sliderGrid from "./modules/sliders/grid";
import sliderLogo from "./modules/sliders/logo";
import sliderTestimonial from "./modules/sliders/testimonial";
import test from "./modules/test";
import coreWebVitals from "./modules/tests/core-web-vitals";
import utils from "./modules/utils";
import video from "./modules/video";
import woocommerce from "./modules/woocommerce";

// Utils
// Format phone numbers automatically
utils.e10kFormatPhoneNumbers();

// Remove empty paragraph tags
utils.e10kRemoveEmptyPTags(
	document.querySelectorAll(
		".site-above-footer-wrap .ast-builder-html-element, .site-footer-primary-section-2 .ast-builder-html-element, .site-footer-primary-section-3 .ast-builder-html-element, .site-footer-primary-section-4 .ast-builder-html-element"
	)
);

// utils.e10kInViewport();

// ADA - Accessibility scripts, mainly fixes
ada.init();

// Astra - Theme scripts
astra.init();

// Pagination
// pagination.init();

// Dialogs
dialogContact.init();
dialogDonate.init();
dialogEventRegistration.init();
dialogScheduleService.init();
dialogVideo.init();
dialogImage.init();

// Gallery
gallery.init();

// Sliders
sliderAircraft.init();
sliderBanner.init();
sliderCta.init();
sliderGallery.init();
sliderGrid.init();
// sliderLogo.init();
sliderTestimonial.init();

// Tests
// test.log();
coreWebVitals.init();

// Video
video.init();

// WooCommerce - Plugin scripts
// woocommerce.init();
