import utils from "../utils";

function init() {
	const scheduleServiceDialogEl = document.querySelectorAll(
		".schedule-service-dialog"
	);

	if (scheduleServiceDialogEl.length) {
		const scheduleServiceDialog = dialogEventListeners({
			open: document.querySelectorAll(
				".schedule-service-form-dialog-link"
			),
			dialog: document.querySelector(".schedule-service-dialog"),
			close: document.querySelector(".schedule-service-dialog .close")
		});
	}
}

// Open dialog
function openDialog(event, dialog) {
	event.preventDefault();
	dialog.showModal();

	// Prevent body scrolling
	// ..

	// Autofocus element
	// ..
}

// Close dialog
function closeDialog(dialog) {
	dialog.close();
}

// Dialog add event listeners
function dialogEventListeners(args) {
	// Open
	args.open.forEach((el) => {
		el.addEventListener("click", (event) => {
			openDialog(event, args.dialog);
		});
	});

	// Scroll
	// window.addEventListener("scroll", () => {
	// 	document.documentElement.style.setProperty(
	// 		"--scroll-y",
	// 		`${window.scrollY}px`
	// 	);
	// });

	// Close
	// On ::backdrop click
	args.dialog.addEventListener("click", (event) => {
		if (event.target.nodeName === "DIALOG") {
			closeDialog(args.dialog);
		}
	});
	// On close button click
	args.close.addEventListener("click", (event) => {
		closeDialog(args.dialog);
	});
}

export default { init };
